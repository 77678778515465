import {FC, useState} from 'react';
import {Input} from 'simplekyc-ui-react';
import styled from '@emotion/styled';
import SearchInput from '../components/SearchInput';
// Temporary for now, client inputs the customerId:
const CUSTOMER = 'DUMMY_USER';

const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  font-family: "Open Sans";
`;

const Container = styled.div`
  margin: 5% auto;
  width: 500px;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 3px 8px 0 rgb(0 0 0 / 20%);
  font-family: "Open Sans";
`;

const Display = styled.div`
  & label {
    top: 4px;
    font-family: "Open Sans";
  }
`;

interface props {
    appkey: string | null | undefined,
    company: string,
    searchMagicUrl: string,
}

const DemoForm: FC<props> = ({appkey, company, searchMagicUrl}) => {
    const [query, setQuery] = useState('');
    const [country, setCountry] = useState('AU');
    const [customerId] = useState(CUSTOMER)
    const [abn, setABN] = useState('');
    const [acn, setACN] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [source, setSource] = useState('');
    const [state, setState] = useState('');
    const [status, setStatus] = useState('');
    const [tradingNames, setTradingNames] = useState([])
    const [type, setType] = useState('')
    const [filingId, setFilingId] = useState('');


    function selectResultHandler(selectedResult: Record<any, any>) {
        setABN(selectedResult.abn ?? '');
        setACN(selectedResult.acn ?? '');
        setAddress(selectedResult.address ?? '')
        setName(selectedResult.name ?? '')
        setSource(selectedResult.source ?? '')
        setState(selectedResult.state ?? '')
        setStatus(selectedResult.status ?? '')
        setTradingNames(selectedResult.tradingNames ?? [])
        setType(selectedResult.type ?? '')
        setFilingId(selectedResult.id ?? '');
        // @ts-ignore
        window["skyc"] = selectedResult;
    }

    function selectCountryHandler() {
        setABN('');
        setACN('');
        setAddress('')
        setName('')
        setSource('')
        setState('')
        setStatus('')
        setTradingNames([])
        setType('')
        setFilingId('');
    }

    function covertAddress(address: string, state: string) {
        if (address && state) {
            return address + ", " + state
        } else if (address) {
            return address;
        } else if (state) {
            return state
        } else {
            return "";
        }
    }

    // @ts-ignore
    return (
        <>
            <div style={{margin: "5% auto", width: "500px", padding: "24px"}}>
                <FormGroup style={country === 'AU' ? {height: "50px"} : {}}>
                    <SearchInput
                        query={query}
                        setQuery={setQuery}
                        country={country}
                        customerId={customerId}
                        setCountry={setCountry}
                        onSelectResult={selectResultHandler}
                        onSelectCountry={selectCountryHandler}
                        appkey={appkey}
                        company={company}
                        searchMagicUrl={searchMagicUrl}
                    />
                    {
                        country === "AU"
                            ?
                            <></>
                            :
                            <Input
                                label="Filing ID"
                                value={filingId}
                                onChange={setFilingId}
                            />
                    }
                    {/* <Button label="Sign Up" primary disabled={!(query && abn && acn)}/>*/}
                </FormGroup>
            </div>
            {query && country === 'AU' &&
                <div style={{

                    height: "900px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "space-between",
                }}>
                    <Container>
                        <Display style={{
                            height: "650px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignContent: "space-between",
                        }}>
                            <Input
                                disabled={true}
                                label="ABN"
                                value={abn}
                            />
                            <Input
                                disabled={true}
                                label="ACN"
                                value={acn}
                            />
                            <Input
                                disabled={true}
                                label="Name"
                                value={name}
                            />
                            <Input
                                disabled={true}
                                label="Address"
                                value={covertAddress(address, state)}
                            />
                            <Input
                                disabled={true}
                                label="Status"
                                value={status}
                            />
                            <Input
                                disabled={true}
                                label="Trading Names"
                                value={tradingNames.join(", ")}
                            />
                            <Input
                                disabled={true}
                                label="Source"
                                value={source}
                            />
                            <Input
                                disabled={true}
                                label="Entity Type"
                                value={type}
                            />
                        </Display>
                    </Container>
                    <Container>
                        <Display style={{
                            height: "650px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignContent: "space-between",
                        }}>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.abn</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.acn</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.name</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.address, skyc.state</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.status</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.tradingNames</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.source</label>
                            <label style={{fontFamily: "Open Sans", color: "grey"}}>skyc.type</label>
                        </Display>
                    </Container>
                </div>
            }
        </>
    )
}
export default DemoForm;
