import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const bizidscript: HTMLElement | null = document.getElementById('bizid-script');
const appkey: string = bizidscript ? ((bizidscript as HTMLDivElement).getAttribute("app-key") as string) : "";
const company: string = bizidscript ? ((bizidscript as HTMLDivElement).getAttribute("company") as string) : "";
const searchMagicUrl: string = bizidscript ? ((bizidscript as HTMLDivElement).getAttribute("search-magic-url") as string) : "";

// appkey={bizidscript?.getAttribute('app-key')}
ReactDOM.render(
  <React.StrictMode>
    <App appkey={appkey} company={company} searchMagicUrl={searchMagicUrl} />
  </React.StrictMode>,
  document.getElementById('bizid-widget')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
